import classNames from 'classnames';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import moment from 'moment';
import React, { useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import CircleInfo from 'ui-kit/icons/info/circle-info-icon';
import PlanBadge from 'ui-kit/icons/plan-badge/plan-badge';

import { AutoRefillToggle } from 'components/auto-refill-toggle';
import { PrescriptionDetails } from 'components/prescription-details';
import { PrescriptionStatusBox } from 'components/prescription-status-box';
import { PrescriptionStatuses } from 'components/prescription-statuses';

import { drugSelector } from 'state/drug/drug.selectors';

import { formatPrice } from 'schema/price.schema';

import { RxResult } from 'types/auto-refill';

import { noop } from 'util/function';
import {
    addDays,
    isRxCloseToExpire,
    isRxExpired,
    isRxExpiresToday,
    isRxWhiteCard,
    isShipStatusExpired
} from 'util/prescription';

import './auto-refill-prescription-card.style.scss';
import PrescriptionCardIcon from './prescription-card.icon';
import './prescription-card.style.scss';
import { AutoRefillPrescriptionCardProps } from './types';

function showDebuginfo(card: RxResult) {
    console.group(`${card.dispensedProductName}`);
    console.log('Prescription Card: ' + card.dispensedProductName);
    console.log('rxStatus: ' + card.rxStatus);
    console.log('itemInWorkflow: ' + card.itemInWorkflow);
    console.log('orderLineQueueStatus: ' + card.orderLineQueueStatus);
    console.log('nextFillDate: ' + card.nextFillDate);
    console.log('lastFillDate: ' + card.lastFillDate);
    console.log('shipStatusExpirationDate: ' + addDays(card.lastFillDate, 14).toLocaleDateString('en-US'));
    console.log('isShipStatusExpired: ' + isShipStatusExpired(card.lastFillDate));
    console.log(card);
    console.groupEnd();
}

function findPriceMatch(rxNumber: string, drugDiscountPrices: { price: string; rxNumber: string }[]) {
    // Check if the price exists for the given prescription number.
    const priceMatches = drugDiscountPrices.filter((item) => {
        return item.rxNumber === rxNumber;
    });

    if (priceMatches.length > 0) {
        const price = priceMatches[0].price;
        if (typeof price === 'number') {
            return formatPrice(price).replace('$', '');
        }
        return price;
    }

    return '';
}

export default function AutoRefillPrescriptionCardV2({
    fullRxItem,
    prescriptionName,
    orderStatus,
    refillsLeft,
    rxNumber,
    details = [],
    statuses = [],
    autoRefillBusy,
    autoRefillEnabledForRx,
    autoRefillOnChange,
    autoRefillEligibleForRefill,
    className,
    isAccountInsured,
    isCaliforniaUser = false,
    isBirdiSelect,
    planAlias
}: AutoRefillPrescriptionCardProps) {
    const { t } = useTranslation();
    const nodeRef = useRef(null);
    let detailsWithPrice = details;

    const rxDisplayStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'RX';
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderStatus]);

    const rxNextRefillStatuses = useMemo(() => {
        return statuses.filter((item) => {
            return item.displayType === 'NEXT_REFILL';
        });
    }, [statuses]);

    const orderStatusClassName = orderStatus.toLowerCase().replace(/\/|\s/g, '_');
    const classes = classNames(
        'prescription-card-v2 desktop auto-refill-prescription-card h-100',
        orderStatusClassName,
        className
    );

    const nexRefillContainerClasses = classNames('prescription-card__rx-refills-container', {
        'prescription-card__refill-due': rxNextRefillStatuses[0]?.isRefillDue
    });

    const { drugDiscountPrices } = useSelector(drugSelector);
    const price = findPriceMatch(rxNumber, drugDiscountPrices);

    const isPriceVisible = useMemo(() => {
        const planAlias = drugDiscountPrices.find((item) => item.rxNumber === rxNumber)?.planAlias;
        return (!isAccountInsured || planAlias === 'BRD02') && !!price && price !== 'NA' && Number(price) !== 0;
    }, [drugDiscountPrices, isAccountInsured, price, rxNumber]);

    const isMembership = useMemo(() => {
        return planAlias === 'BRD02';
    }, [planAlias]);

    if (isPriceVisible) {
        detailsWithPrice = [
            ...details,
            {
                detail: (
                    <>
                        {t('components.prescriptionCard.cashPrice')}: <strong>${price}</strong>
                    </>
                )
            }
        ];
    }

    return (
        <div className={classes}>
            {/* Icon Section */}
            <div className="prescription-card__icon_container d-none d-md-flex">
                <CSSTransition nodeRef={nodeRef} in={!!''} timeout={4000} classNames="prescription-card-icon">
                    <div
                        ref={nodeRef}
                        className={'prescription-card__icon-badge-new'}
                        role="button"
                        tabIndex={0}
                        onKeyDown={noop}
                        onClick={() => {
                            showDebuginfo(fullRxItem);
                        }}
                    >
                        {/* As we don't receive the orderSubStatus from Autorefill API, we are adding a default
                        orderSubStatus to select the icon when the orderStatus is equal to ORDERED. */}
                        <PrescriptionCardIcon variant={orderStatus} orderSubStatus={'Processing'} t={t} />
                    </div>
                </CSSTransition>
            </div>

            {/* Content Section */}
            <div className="prescription-card__content-container mw-100">
                <div className="prescription-card__content-details d-flex align-items-center align-items-md-start flex-column">
                    {/* Order Status */}
                    <PrescriptionStatusBox statuses={rxDisplayStatuses} />

                    {/* Prescription Name */}
                    <div className="prescription-card__title-info-container">
                        <h4 className="prescription-card__title" title={prescriptionName?.toUpperCase()}>
                            {prescriptionName}
                            {isMembership && isBirdiSelect && (
                                <div className="prescription-card__title-badge">
                                    <PlanBadge variant="birdi-select-blue" />
                                </div>
                            )}
                        </h4>
                        {isMembership && isBirdiSelect && (
                            <div className="prescription-card__title-badge-mobile">
                                <PlanBadge variant="birdi-select-blue" />
                            </div>
                        )}
                    </div>

                    {/* RX Number & Details */}

                    <div className="prescription-card__rx-details-container d-none d-md-flex">
                        <PrescriptionDetails details={detailsWithPrice} expanded={false} />
                    </div>
                    <div className="prescription-card__rx-details-container d-flex d-md-none w-100 justify-content-center">
                        <PrescriptionDetails
                            details={[
                                {
                                    detail: t('components.prescriptionCard.quantity', {
                                        fillQuantity: fullRxItem.fillQuantity
                                    })
                                }
                            ]}
                            expanded={false}
                        />
                    </div>

                    {/* Refill Status */}
                    {!isRxWhiteCard(orderStatus) && (
                        <div
                            className={classNames('prescription-card__refills', {
                                'prescription-card__refills--empty': refillsLeft === 0
                            })}
                        >
                            {refillsLeft > 0 && (
                                <>
                                    <span className="d-none d-md-flex">
                                        {t('components.prescriptionCard.orderRefillAva')}
                                    </span>
                                    <span className="divider d-none d-md-flex">|</span>
                                </>
                            )}
                            <span className={refillsLeft === 1 ? 'text-warning' : ''}>{refillsLeft}</span>
                            <span className={refillsLeft === 1 ? 'text-warning' : ''}>
                                {t('components.prescriptionCard.refillsLeftTitle')}
                            </span>
                        </div>
                    )}

                    {/* Next Refill */}
                    <div className={nexRefillContainerClasses}>
                        <div>
                            {rxNextRefillStatuses.length > 0 && (
                                <div className="prescription-card__next-refill">
                                    <div className="prescription-card__details-status-headings">
                                        {rxNextRefillStatuses[0]?.isRefillDue ? (
                                            <>
                                                <div className="icon">
                                                    <CircleInfo />
                                                </div>
                                                {t('components.prescriptionCard.orderRefillDue')}
                                            </>
                                        ) : (
                                            <>
                                                <div className="d-none d-md-block">
                                                    {t('components.prescriptionCard.orderNextRefill')}
                                                </div>
                                                <div className="d-md-none">
                                                    {t('components.prescriptionCard.orderNextRefillCollapsed')}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <PrescriptionStatuses statuses={rxNextRefillStatuses} />
                                </div>
                            )}
                        </div>
                    </div>

                    {!!fullRxItem.lastFillDate && (orderStatus === 'EXPIRED' || orderStatus === 'OUT_OF_REFILLS') && (
                        <div className="prescription-card__rx-refills-container">
                            <div className="prescription-card__next-refill">
                                <div className="prescription-card__details-status-headings">
                                    {t('components.prescriptionCard.orderLastFilled')}{' '}
                                    <strong>{fullRxItem.lastFillDate}</strong>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Cart & Autorefill */}
            <div className="prescription-card__cart_container d-none d-md-flex flex-column align-items-center justify-content-start col-12 col-md-auto">
                {autoRefillEligibleForRefill && (
                    <AutoRefillToggle
                        onChange={(val: boolean) => autoRefillOnChange && autoRefillOnChange([fullRxItem], val)}
                        isBusy={autoRefillBusy ?? false}
                        t={t}
                        rxEligible={autoRefillEligibleForRefill ?? false}
                        checked={autoRefillEnabledForRx ?? false}
                        isConsentExpiresSoon={
                            isRxCloseToExpire(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY')) ||
                            isRxExpiresToday(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY'))
                        }
                        isConsentExpired={isRxExpired(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY'))}
                        isCaliforniaUser={isCaliforniaUser}
                    />
                )}
            </div>
            <div className="prescription-card__cart_container d-flex d-md-none flex-column align-items-center justify-content-start col-12 col-md-auto p-0">
                <div className="auto-refill-toggle-label">
                    <span>{t('components.autoRefillToggle.label')}</span>
                </div>
                {autoRefillEligibleForRefill && (
                    <AutoRefillToggle
                        onChange={(val: boolean) => autoRefillOnChange && autoRefillOnChange([fullRxItem], val)}
                        isBusy={autoRefillBusy ?? false}
                        t={t}
                        className="p-0"
                        isLabelVisible={false}
                        rxEligible={autoRefillEligibleForRefill ?? false}
                        checked={autoRefillEnabledForRx ?? false}
                        isConsentExpiresSoon={
                            isRxCloseToExpire(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY')) ||
                            isRxExpiresToday(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY'))
                        }
                        isConsentExpired={isRxExpired(moment(fullRxItem.consentExpiration).format('MM/DD/YYYY'))}
                        isCaliforniaUser={isCaliforniaUser}
                    />
                )}
            </div>
        </div>
    );
}
