import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

// Utils
import useWindowDimensions from 'hooks/useWindowDimensions';
import { ellipsify, lowercaseAndCapitalize } from 'util/cart';
import { getCreditCardEnding } from 'util/payments';

// UI Kit & Components
import ColumnSection from 'components/sidebar-column/column-section/column-section.component';
import CreditCardIcon from 'ui-kit/icons/creditcard-icon/creditcard-icon';
import EmptyAlertBox from 'components/medicine-cabinet-cart/empty-alert-box/empty-alert-box';

// auto Refill
import {
    autoRefillDefaultPaymentSelector,
    autoRefillPaymentCardsSelector
} from 'state/auto-refill/auto-refill.selectors';

import './deafult-payment-method.style.scss';

const DefaultPaymentMethod: React.FC = () => {
    const autoRefillPaymentCards = useSelector(autoRefillPaymentCardsSelector);
    const defaultPayment = useSelector(autoRefillDefaultPaymentSelector);

    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const isInnerWidthViewportRef = React.useRef<boolean>(false);
    const hasPaymentData = autoRefillPaymentCards && autoRefillPaymentCards.length > 0;

    useEffect(() => {
        if (width < 1200) {
            isInnerWidthViewportRef.current = true;
        } else {
            isInnerWidthViewportRef.current = false;
        }
    }, [width]);

    return (
        <ColumnSection
            className="payment-method payment-method__autorefill"
            headerClassName="payment-method__header"
            title={t('components.medicineCabinetCart.paymentMethod.title')}
        >
            <>
                <div>
                    {t('pages.autoRefill.prescriptions.paymentInformation.sentenceOne')}
                    <a href="/sign-in">{t('pages.autoRefill.prescriptions.paymentInformation.signIn')}</a>
                    {t('pages.autoRefill.prescriptions.paymentInformation.sentenceTwo')}
                </div>
                {hasPaymentData ? (
                    <>
                        {defaultPayment && (
                            <div className="default-payment-method">
                                <div className="default-payment-method-type">
                                    {t('components.medicineCabinetCart.paymentMethod.primaryPayment')}
                                </div>
                                <div className="default-payment-method-info">
                                    <div className="default-payment-method-info-text">
                                        <p>{`${
                                            isInnerWidthViewportRef?.current
                                                ? ellipsify(lowercaseAndCapitalize(defaultPayment.cardType))
                                                : defaultPayment.cardType
                                        } ${getCreditCardEnding(defaultPayment.secureCardNumber, '····')}`}</p>
                                        <p>{` ${defaultPayment.cardName}`}</p>
                                        <p>
                                            {t('components.medicineCabinetCart.paymentMethod.expiry', {
                                                month: defaultPayment.cardMonthNum,
                                                year: defaultPayment.cardYear.slice(-2)
                                            })}
                                        </p>
                                    </div>
                                    <CreditCardIcon
                                        className="payment-card__credit-card-icon"
                                        variant={defaultPayment.cardType}
                                    />
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <EmptyAlertBox text={t('pages.cart.emptyPaymentMethod')} />
                )}
            </>
        </ColumnSection>
    );
};

export default DefaultPaymentMethod;
