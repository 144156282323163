import React from 'react';
import { useTranslation } from 'react-i18next';

// UI Kit & Components
import SidebarColumn from 'components/sidebar-column/sidebar-column.component';
import PlusIcon from 'ui-kit/icons/plus-icon/plus-icon';
import DefaultAddress from './default-address/default-address.component';
import DefaultPaymentMethod from './default-payment-method/default-payment-method.component';

import './auto-refill-other-info.style.scss';

const AutoRefillOtherInfo: React.FC = () => {
    const { t } = useTranslation();

    return (
        <SidebarColumn isSidebarVisible={true} className="auto-refill-other-info mt-0">
            <div className="auto-refill-other-info__header">
                <PlusIcon type="third" className={'header-icon-container'} />
                <h3>{t('pages.autoRefill.otherInformation.title')}</h3>
            </div>
            <>
                <DefaultAddress />
                <DefaultPaymentMethod />
            </>
        </SidebarColumn>
    );
};

export default AutoRefillOtherInfo;
