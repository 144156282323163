import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
    autoRefillePostPatientNumberSelector,
    autoRefillFamilyDataSelector,
    autoRefillFlagsSelector,
    autoRefillIsCaregiverSelector
} from 'state/auto-refill/auto-refill.selectors';
import {
    autoRefillFlagsSelector as easyRefillAutoRefillFlagsSelector,
    easyRefillFamilyDependentsSelector,
    easyRefillIsCaregiverSelector
} from 'state/easy-refill/easy-refill.selectors';

import { AutoRefillFlag } from 'types/auto-refill';

import { AutoRefillFlows } from './useAutoRefillToggle';

interface AutoRefillPropsSearch {
    rxNumber: string;
    activeDependent: string | null | undefined;
    property?: 'autoRefillEnabled' | 'autoRefillEligible';
}

export const useUnAuthAutoRefill = () => {
    // Auto refill data
    const autoRefillIsCaregiver = useSelector(autoRefillIsCaregiverSelector);
    const autoRefillFlagsData = useSelector(autoRefillFlagsSelector);
    const autoRefillFamilyData = useSelector(autoRefillFamilyDataSelector);
    const autoRefillEpostNum = useSelector(autoRefillePostPatientNumberSelector);

    // Easy refill data
    const easyRefillIsCaregiver = useSelector(easyRefillIsCaregiverSelector);
    const easyRefillFamilyDependents = useSelector(easyRefillFamilyDependentsSelector);
    const easyRefillAutoRefillFlags = useSelector(easyRefillAutoRefillFlagsSelector);

    // Load memoized data
    // TODO: Re-structure this data to be managed from the sagas, so we won't need
    // to manually switch the type of data. Ideally, BE will return consistent values too.
    const flow = useMemo(
        () => (autoRefillEpostNum ? AutoRefillFlows.autoRefill : AutoRefillFlows.easyRefill),
        [autoRefillEpostNum]
    );

    const isCaregiver = useMemo(
        () => (flow === AutoRefillFlows.autoRefill ? autoRefillIsCaregiver : easyRefillIsCaregiver),
        [flow, autoRefillIsCaregiver, easyRefillIsCaregiver]
    );

    const familyData = useMemo(
        () => (flow === AutoRefillFlows.autoRefill ? autoRefillFamilyData : easyRefillFamilyDependents),
        [flow, autoRefillFamilyData, easyRefillFamilyDependents]
    );

    const autoRefillFlags = useMemo(
        () => (flow === AutoRefillFlows.autoRefill ? autoRefillFlagsData : easyRefillAutoRefillFlags),
        [flow, autoRefillFlagsData, easyRefillAutoRefillFlags]
    );

    // For context, the structure of the API response, returns the auto refill
    // properties in a different array, so we have this function to find the results
    // of a certain property gotten.
    const getAutoRefillProperty = useCallback(
        ({ rxNumber, activeDependent = null, property = 'autoRefillEnabled' }: AutoRefillPropsSearch) => {
            const findRx = (autoRefillArr?: AutoRefillFlag[]) => {
                if (!autoRefillArr) return null;
                return autoRefillArr.find((rx: AutoRefillFlag) => rx.rxNumber === rxNumber);
            };

            if (isCaregiver && activeDependent) {
                const selectedEligibleRxs = familyData.find(
                    (dependent) => dependent.epostPatientNum === activeDependent
                );

                const foundRx = findRx(selectedEligibleRxs?.autoRefillFlags);
                if (!foundRx || !property) return false;
                return foundRx[property];
            }
            const foundRx = findRx(autoRefillFlags);
            if (!foundRx) return false;
            return foundRx[property];
        },
        [autoRefillFlags, familyData, isCaregiver]
    );

    // Function that gets if the auto refill toggle is enabled for a prescription
    const rxAutoRefillEnabled = useCallback(
        (rxNumber: string, activeDependent: string | null | undefined = null) =>
            getAutoRefillProperty({ rxNumber, activeDependent, property: 'autoRefillEnabled' }),
        [getAutoRefillProperty]
    );

    // Function that gets if the Rx is eligible for auto refill for a certain prescription
    const rxAutoRefillEligible = useCallback(
        (rxNumber: string, activeDependent: string | null | undefined = null) =>
            getAutoRefillProperty({ rxNumber, activeDependent, property: 'autoRefillEligible' }),
        [getAutoRefillProperty]
    );

    return { rxAutoRefillEnabled, rxAutoRefillEligible };
};
